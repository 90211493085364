@import './variables';
@import './mixins';

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

html,
body {
  width: 100%;
  margin: 0;
  font-family: sans-serif;
  font-size: 12px;
}

main {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 0 20px $footer-height;

  @media (min-width: $sm) {
    width: 60%;
  }
}

footer {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0;
  width: 100%;
  height: $footer-height;
  background: inherit;
  font-size: 18px;
  color: $blue;
  z-index: 100;
  cursor: pointer;
}

figure {
  position: relative;
  width: 100%;
  margin: 0;
  padding: 0;

  h1 {
    font-weight: normal;
  }
}

svg {
  @include transform(translateZ(0));
}

path,
rect {
  vector-effect: non-scaling-stroke;
}

.plot,
.timeline {
  position: relative;

  path {
    fill: none;
    opacity: 1;
    transition: opacity $base-animation-duration ease-in-out;

    &.hidden {
      opacity: 0;
    }
  }
}

.plot {
  width: 100%;
  height: 400px;
  margin-bottom: 40px;

  path {
    stroke-width: 2px;
  }
}

.timeline {
  position: relative;
  width: 100%;
  height: 70px;
  margin-top: 10px;

  path {
    stroke-width: 1px;
  }

  .selection {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    user-select: none;

    div {
      position: absolute;
      top: 0;
      height: 100%;
    }

    .selector {
      @include hitbox(10px);

      background: $light-blue;
      opacity: 0.4;
      cursor: col-resize;
      z-index: 2;
    }

    .area {
      background: transparent;
      border: 2px solid $light-blue;
      opacity: 0.4;
      cursor: grab;

      &.dragging {
        cursor: grabbing;
      }
    }

    .background {
      background: $lighter-blue;
      opacity: 0.7;
    }
  }
}

.xaxis {
  position: relative;
  width: 100%;
  height: 20px;
  overflow: hidden;

  .label {
    position: absolute;
    top: 4px;
    opacity: 1;
    z-index: -1;
    font-weight: lighter;
    color: $darker-gray;
    text-align: center;
    white-space: nowrap;
    transition: opacity 200ms ease-in-out;

    &.hidden {
      opacity: 0;
    }
  }

  .shadow {
    position: absolute;
    z-index: 1;
    width: 30px;
    height: 100%;
    top: 0;

    &.right {
      @include horizontal-gradient(rgba($white, 0), $white);

      right: 0;
    }

    &.left {
      @include horizontal-gradient($white, rgba($white, 0));

      left: 0;
    }
  }
}

.yaxis {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  overflow: hidden;

  .label {
    position: absolute;
    left: 0;
    width: 100%;
    margin-top: -19px;
    opacity: 1;
    transition: all $base-animation-duration ease-in-out;
    transform: translateY(0);
    z-index: -1;

    .label-line {
      margin: 0;
      width: 100%;
      border: 0;
      border-bottom: 1px solid $gray;
    }

    .label-text {
      display: block;
      font-weight: lighter;
      color: $darker-gray;
      margin-bottom: 4px;
    }

    &.hidden {
      opacity: 0;
    }
  }
}

.legend {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  .legend-data {
    position: absolute;
    padding: 8px 10px;
    top: 5px;
    z-index: 11;
    border: 1px solid $gray;
    border-radius: 6px;
    background: $white;
    box-shadow: 0px 0px 2px darken($gray, 5%);
    transform: translateX(-50%);
    font-size: 14px;
    white-space: nowrap;
    pointer-events: none;
    opacity: 0;
    transition: all 50ms linear;

    .values {
      display: flex;
      font-weight: bold;
      margin-top: 4px;
    }
    .values div {
      margin-right: 18px;
    }
    .values span {
      font-weight: normal;
      font-size: 12px;
    }
  }

  .legend-line {
    position: absolute;
    z-index: -1;
    margin: 0;
    top: 10px;
    left: 0;
    height: calc(100% - 10px);
    border: 0;
    border-left: 1px solid darken($gray, 10%);
    pointer-events: none;
    opacity: 0;
    transition: all 50ms linear;
  }

  .legend-circle {
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    width: 12px;
    height: 12px;
    border: 3px solid;
    background: $white;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;
    opacity: 0;
    transition: all 50ms linear;
  }

  &.visible {
    opacity: 1;
  }
}

.filter {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: 6px 0;

  .filter-button {
    position: relative;
    display: flex;
    height: $filter-button-height;
    align-items: center;
    margin-right: 16px;
    margin-bottom: 8px;
    padding: 0 #{$filter-button-height / 4};
    border: 1px solid $gray;
    border-radius: $filter-button-height / 2;
    font-size: 14px;
    cursor: pointer;
    user-select: none;

    .filter-circle {
      position: relative;
      width: $filter-circle-size;
      height: $filter-circle-size;
      border: 2px solid transparent;
      border-radius: 50%;
      color: $white;
      text-align: center;
      transition: border $base-animation-duration ease-in-out;
    }

    .filter-name {
      margin-left: $filter-button-height / 4;
      margin-right: $filter-button-height / 8;
    }

    &:before {
      content: '';
      position: absolute;
      display: block;
      top: $filter-button-height / 2 - $filter-checkmark-size / 2;
      left: $filter-button-height / 4 + $filter-circle-size / 2 -
        $filter-checkmark-size / 2;
      width: $filter-checkmark-size;
      height: $filter-checkmark-size;
      background: $checkmark-icon;
      background-repeat: no-repeat;
      background-size: cover;
      z-index: 1;
      opacity: 0;
      transition: opacity $base-animation-duration ease-in-out;
    }

    &.checked {
      .filter-circle {
        border-width: $filter-circle-size / 2;
      }

      &:before {
        opacity: 1;
      }
    }
  }
}

/* Theme */
body {
  &.day {
    background: $white;
    color: $black;

    footer {
      .night {
        display: inline;
      }

      .day {
        display: none;
      }
    }
  }

  &.night {
    background: $dark-gray;
    color: $white;

    .yaxis {
      .label {
        .label-line {
          border-color: $alt-gray;
        }

        .label-text {
          color: $lighter-alt-gray;
        }
      }
    }

    .xaxis {
      .label {
        color: $lighter-alt-gray;
      }

      .shadow {
        &.right {
          @include horizontal-gradient(rgba($dark-gray, 0), $dark-gray);
        }

        &.left {
          @include horizontal-gradient($dark-gray, rgba($dark-gray, 0));
        }
      }
    }

    .timeline {
      .selection {
        .selector {
          background: $white;
        }

        .area {
          border-color: $white;
        }

        .background {
          background: darken($dark-gray, 3%);
        }
      }
    }

    .legend {
      .legend-data {
        background: $dark-gray;
        border-color: $dark-gray;
        box-shadow: 0px 0px 2px darken($dark-gray, 5%);
      }

      .legend-circle {
        background: $dark-gray;
      }

      .legend-line {
        border-color: lighten($dark-gray, 10%);
      }
    }

    .filter {
      .filter-button {
        border-color: $alt-gray;
      }
    }

    footer {
      .night {
        display: none;
      }

      .day {
        display: inline;
      }
    }
  }
}
