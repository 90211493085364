@mixin transform($transform) {
  -moz-transform: $transform;
  -o-transform: $transform;
  -ms-transform: $transform;
  -webkit-transform: $transform;
  transform: $transform;
}

@mixin rotate($deg) {
  @include transform(rotate(#{$deg}deg));
}

@mixin scale($scale) {
  @include transform(scale($scale));
}

@mixin translate($x, $y) {
  @include transform(translate($x, $y));
}

@mixin skew($x, $y) {
  @include transform(skew(#{$x}deg, #{$y}deg));
}

@mixin backface-visibility($value) {
  -webkit-backface-visibility: $value;
  -moz-backface-visibility: $value;
  -ms-backface-visibility: $value;
  backface-visibility: $value;
}

@mixin perspective($value) {
  -webkit-perspective: $value;
  -moz-perspective: $value;
  -ms-perspective: $value;
  perspective: $value;
}

@mixin transform-style($value) {
  -webkit-transform-style: $value;
  -moz-transform-style: $value;
  -ms-transform-style: $value;
  transform-style: $value;
}

@mixin hitbox($margin) {
  &:before {
    content: '';
    position: absolute;
    top: -$margin;
    left: -$margin;
    right: -$margin;
    bottom: -$margin;
  }
}

@mixin horizontal-gradient($from, $to) {
  background: -moz-linear-gradient(left, $from 0%, $to 100%);
  background: -webkit-linear-gradient(left, $from 0%, $to 100%);
  background: linear-gradient(to right, $from 0%, $to 100%);
}
