$checkmark-icon: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHg9IjBweCIgeT0iMHB4IiB3aWR0aD0iNTEyIiBoZWlnaHQ9IjUxMiIgdmlld0JveD0iMCAwIDQwNS4yNzIgNDA1LjI3MiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNDA1LjI3MiA0MDUuMjcyOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSIgY2xhc3M9IiI+PGc+PGc+Cgk8cGF0aCBkPSJNMzkzLjQwMSwxMjQuNDI1TDE3OS42MDMsMzM4LjIwOGMtMTUuODMyLDE1LjgzNS00MS41MTQsMTUuODM1LTU3LjM2MSwwTDExLjg3OCwyMjcuODM2ICAgYy0xNS44MzgtMTUuODM1LTE1LjgzOC00MS41MiwwLTU3LjM1OGMxNS44NDEtMTUuODQxLDQxLjUyMS0xNS44NDEsNTcuMzU1LTAuMDA2bDgxLjY5OCw4MS42OTlMMzM2LjAzNyw2Ny4wNjQgICBjMTUuODQxLTE1Ljg0MSw0MS41MjMtMTUuODI5LDU3LjM1OCwwQzQwOS4yMyw4Mi45MDIsNDA5LjIzLDEwOC41NzgsMzkzLjQwMSwxMjQuNDI1eiIgZGF0YS1vcmlnaW5hbD0iIzAwMDAwMCIgY2xhc3M9ImFjdGl2ZS1wYXRoIiBzdHlsZT0iZmlsbDojRkZGRkZGIiBkYXRhLW9sZF9jb2xvcj0iI2ZmZmZmZiI+PC9wYXRoPgo8L2c+PC9nPiA8L3N2Zz4=);

$white: #ffffff;
$black: #000000;
$gray: #e6ecf0;
$darker-gray: #96a2aa;
$dark-gray: #242f3e;
$alt-gray: #344658;
$lighter-alt-gray: #546778;
$lighter-blue: #f5f9fb;
$light-blue: #a4c7df;
$blue: #118be3;

$sm: 768px;

$base-animation-duration: 300ms;
$filter-button-height: 36px;
$filter-circle-size: 22px;
$filter-checkmark-size: 10px;
$footer-height: 50px;
